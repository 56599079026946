<template>
  <div>
    <v-card v-if="campaigns.length === 0">
      <img v-if="$vuetify.breakpoint.mdAndUp" src="@/assets/img/proposition/proposal_background-sm.jpg"/>
      <img v-else-if="$vuetify.breakpoint.smOnly" src="@/assets/img/proposition/proposal_background-sm.jpg" style="width: 100%"/>
      <img v-else src="@/assets/img/proposition/proposal_background-xs.jpg"/>
      <v-row align="center" justify="center" class="font-weight-bold" style="color: #484848; font-size:16px">
        아직 등록된 캠페인이 없습니다.
      </v-row>
      <v-row align="center" justify="center" class="my-4 text-center" style="color: #808080; font-size:14px">
        지금 캠페인을 등록하고, 마음에 드는 인플루언서에게<br>
        제안을 보내보세요.
      </v-row>
      <v-row align="center" justify="center" class="py-6">
        <v-btn flat tile color="rouge white--text" to="/regcam/">
          새 캠페인 등록하기
        </v-btn>
      </v-row>
    </v-card>
    <v-card class="py-5" style="background-color: #F2F2F2" v-else-if="proposeDetail">
      <div>
        <v-toolbar flat class="pl-4 mb-4" @click="proposeDetail=false; $router.push({ path: 'autoproposition', query: {tab: 1}})" style="cursor:pointer">
          <v-icon>chevron_left</v-icon> <span class="ml-5">목록보기</span>
        </v-toolbar>
      </div>
      <AutoResultDetail @detail:change="(val) => openProposeDetail=val"
                        :propositions="openProposeDetail.propositions"
                        :propositionAutos="propositionAutos">
      </AutoResultDetail>
    </v-card>
    <v-card v-else flat class="py-4" style="background-color: #F2F2F2" :style="$vuetify.breakpoint.mdOnly ? 'width: calc(100vw - 240px); overflow-x:auto; white-space: nowrap;' : 'width:1120px'">
      <v-row v-for="(propositionAuto, idx) in propositionAutos" :key="idx">
        <v-card flat class="mb-4 listwidth" v-if="idx >= ((page - 1) * itemsPerPage) && idx < (itemsPerPage * page)" @click="proposeDetail=true;openProposeDetail=propositionAuto">
          <v-list :two-line="$vuetify.breakpoint.mdAndUp" :three-line="$vuetify.breakpoint.smAndDown">
            <v-list-item style="background-color: white">
              <v-list-item-content style="min-width: 292px">
                <v-list-item-title :style="$vuetify.breakpoint.mdAndUp ? 'font-size: 13px;color: #484848;' : 'font-size: 12px;color: #484848;'">
                  {{$vuetify.breakpoint.mdAndUp ? '자동 제안 명' : propositionAuto.name}}
                </v-list-item-title>
                <v-list-item-subtitle class="body-1 mt-3" :style="$vuetify.breakpoint.mdAndUp ? 'color: #484848;' : ('color: ' + statusMap[propositionAuto.status || 0].color + ';font-size:12px !important')" style="line-height:24px">
                  <v-icon class="hidden-md-and-up" :color="statusMap[propositionAuto.status || 0].color">{{statusMap[propositionAuto.status || 0].icon}}</v-icon>
                  {{$vuetify.breakpoint.mdAndUp ? propositionAuto.name : statusMap[propositionAuto.status || 0].text}}
                </v-list-item-subtitle>
                <v-row class="hidden-md-and-up mt-4">
                  <v-col>
                    <v-list-item-title style="font-size: 10px;color: #484848;">총 인원</v-list-item-title>
                    <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;font-size:12px !important;">{{propositionAuto.propositions.length + '명'}}</v-list-item-subtitle>
                  </v-col>
                  <v-col>
                    <v-list-item-title style="font-size: 10px;color: #484848;">수락 인원</v-list-item-title>
                    <v-list-item-subtitle class="body-1 mt-3" v-text="propositionAuto.propositions.length - _.filter(propositionAuto.propositions, {'status': 1}).length - _.filter(propositionAuto.propositions, {'status': 3}).length + '명'" style="color: #484848;font-size:12px !important;"></v-list-item-subtitle>
                  </v-col>
                  <v-col>
                    <v-list-item-title style="font-size: 10px;color: #484848;">대기중 인원</v-list-item-title>
                    <v-list-item-subtitle class="body-1 mt-3" v-text="_.filter(propositionAuto.propositions, {'status': 1}).length + '명'" style="color: #484848;font-size:12px !important;"></v-list-item-subtitle>
                  </v-col>
                  <v-col>
                    <v-list-item-title style="font-size: 10px;color: #484848;">거절</v-list-item-title>
                    <v-list-item-subtitle class="body-1 mt-3" v-text="_.filter(propositionAuto.propositions, {'status': 3}).length + '명'" style="color: #BD1328;font-size:12px !important;"></v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-content style="min-width: 140px" v-if="$vuetify.breakpoint.mdAndUp">
                <v-list-item-title style="font-size: 13px;color: #484848;">진행 단계</v-list-item-title>
                <v-list-item-subtitle class="body-1 mt-3" :style="'color: ' + statusMap[propositionAuto.status || 0].color">
                  <v-icon size="20" class="mb-1" :color="statusMap[propositionAuto.status || 0].color">{{statusMap[propositionAuto.status || 0].icon}}</v-icon>
                  {{statusMap[propositionAuto.status || 0].text}}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content style="min-width: 140px" v-if="$vuetify.breakpoint.mdAndUp">
                <v-list-item-title style="font-size: 13px;color: #484848;">집행 금액</v-list-item-title>
                <v-list-item-subtitle class="body-1 mt-3" style="color: #484848; line-height:24px">₩{{propositionAuto.budget | numFormat}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content style="min-width: 140px" v-if="$vuetify.breakpoint.mdAndUp">
                <v-list-item-title style="font-size: 13px;color: #484848;">소진 금액</v-list-item-title>
                <v-list-item-subtitle class="body-1 mt-3" style="color: #484848; line-height:24px">₩{{amountUsed(propositionAuto) | numFormat}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content style="min-width: 140px" v-if="$vuetify.breakpoint.mdAndUp">
                <v-list-item-title style="font-size: 13px;color: #484848;">응답 대기 중</v-list-item-title>
                <v-list-item-subtitle class="body-1 mt-3" v-text="_.filter(propositionAuto.propositions, {'status': 1}).length" style="color: #484848; line-height:24px"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content style="min-width: 110px" v-if="$vuetify.breakpoint.mdAndUp">
                <v-list-item-title style="font-size: 13px;color: #484848;">전체 제안 인원</v-list-item-title>
                <v-list-item-subtitle class="body-1 mt-3" v-text="propositionAuto.propositions.length" style="color: #484848; line-height:24px"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-row>
      <div class="text-center mt-3" style="background-color: white">
        <v-pagination
            v-model="page"
            :length="(propositionAutos.length - 1)/itemsPerPage + 1"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
  import userMixin from '@/components/shared/userMixin';
  import campaignMixin from '@/components/shared/campaignMixin';
  import {EventBus} from "@/components/shared/event-bus";

  export default {
    name: "AutoResult",
    mixins: [userMixin, campaignMixin],
    components: {
      AutoResultDetail: () => import('@/components/campaign/autoproposition/AutoResultDetail')
    },
    data() {
      return {
        propositionAutos: [],
        page: 1,
        itemsPerPage: 10,
        proposeDetail: false,
        openProposeDetail: null,
        statusMap: {
          0: {text: '작성중', icon: 'mdi-plus-circle', color: '#4770bd'},
          1: {text: '제안 진행 중', icon: 'mdi-dots-horizontal-circle', color: '#34cd89'},
          2: {text: '일시 중지', icon: 'mdi-pause-circle-outline', color: '#484848'},
          98: {text: '종료', icon: 'mdi-stop', color: '#c2c2c2'},
          99: {text: '종료', icon: 'mdi-stop', color: '#c2c2c2'}
        }
      }
    },
    computed: {
    },
    watch: {
    },
    methods: {
      async getPropositionAutos() {
        if (this.campaigns.length > 0) {
          let query = '';
          this.campaigns.map((campaign, idx) => {
            query += 'campaignId=' + campaign.id;
            if (idx !== this.campaigns.length - 1) {
              query += ' OR '
            }
          });
          let propositionAutos = await this.axios.get('/campaign/propositionAuto?query=' + encodeURIComponent(query) + '&orderBy=id&direction=desc');
          this.propositionAutos = this._.filter(propositionAutos.data.propositionAutos, (propositionAuto) => {
            return propositionAuto.status > 0;
          });
        }
      },
      amountUsed(propositionAuto) {
        return this._.sumBy(propositionAuto.propositions.filter((p) => (p.status == 2 || p.status >= 10)), 'point') +
            this._.sumBy(propositionAuto.propositions.filter((p) => ((p.status === 2 || p.status >= 10) && p.rewardFeeAmount > 0)), 'cprice') +
            this._.sumBy(propositionAuto.propositions.filter((p) => ((p.status === 2 || p.status >= 10) && p.rewardFeeAmount > 0)), 'rewardFeeAmount');
      }
    },
    async created() {
      await this.getPropositionAutos();
      EventBus.$on("update:result", async () => {
        await this.getPropositionAutos();
      });
    }
  }
</script>

<style scoped lang="scss">
  .edit-button {
    pointer-events: auto;
  }

  ::v-deep .v-expansion-panel__header {
    padding: 0px;
  }

  .listwidth {
    width: 100%;
    @media(max-width: 1080px) {
      width: 100vw;
    }
  }
</style>